// src/redux/authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-toastify';

const initialState = {
  user: null,
  token: localStorage.getItem('auth_token') || null,
  refreshToken: localStorage.getItem('refresh_token') || null,
  status: 'idle',
  error: null,
};
const backendUrl = process.env.REACT_APP_BACKEND_URL;

export const login = createAsyncThunk(
  'auth/login',
  async (loginForm, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.post(`${backendUrl}/auth/login`, loginForm);
      localStorage.setItem('auth_token', response.data.result.access_token);
      localStorage.setItem('refresh_token', response.data.result.refresh_token);
      localStorage.setItem('auth_token_type', response.data.result.token_type);
      toast.success(response.data.detail);
      return response.data.result;
    } catch (error) {
      toast.error(error.response.data.detail);
      return rejectWithValue(error.response.data);
    }
  }
);


export const register = createAsyncThunk(
  'auth/register',
  async (registerForm, { dispatch, rejectWithValue }) => {
    try {
      // console.log(registerForm)
      const response = await axios.post(`${backendUrl}/auth/register`, registerForm);
      // console.log("hol")
      // localStorage.setItem('auth_token', response.data.result.access_token);
      // localStorage.setItem('refresh_token', response.data.result.refresh_token);
      // localStorage.setItem('auth_token_type', response.data.result.token_type);
      toast.success(response.data.detail);
      return response.data.result;
    } catch (error) {
      toast.error(error.response.data.detail);
      return rejectWithValue(error.response.data);
    }
  }
);


export const googleLogin = createAsyncThunk(
  'auth/googleLogin',
  async (token, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${backendUrl}/auth/google`, {
        token,
      });
      localStorage.setItem('auth_token', response.data.result.access_token);
      localStorage.setItem('refresh_token', response.data.result.refresh_token);
      localStorage.setItem('auth_token_type', response.data.result.token_type);
      toast.success(response.data.detail);
      return response.data.result;
    } catch (error) {
      toast.error(error.response.data.detail);
      return rejectWithValue(error.response.data);
    }
  }
);


export const fetchUser = createAsyncThunk(
  'auth/fetchUser',
  async (_, { getState, rejectWithValue, dispatch}) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const response = await axios.get(`${backendUrl}/users`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data.result;
    } catch (error) {

      if (error.response && error.response.status === 401) {
        toast.error('Session expired. Please log in again.');
        dispatch(logout());
        return rejectWithValue('Unauthorized');
      }
      return rejectWithValue(error.response.data);
    }
  }
);
  
export const refreshToken = createAsyncThunk(
  'auth/refreshToken',
  async (_, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const refreshToken = state.auth.refreshToken;
      const response = await axios.post(`${backendUrl}auth/refresh-token`, {
        refresh_token: refreshToken,
      });
      localStorage.setItem('auth_token', response.data.result.access_token);
      return response.data.result;
    } catch (error) {
      toast.error('Session expired. Please log in again.');
      return rejectWithValue(error.response.data);
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null;
      state.token = null;
      state.refreshToken = null;
      state.status = 'idle';
      localStorage.removeItem('auth_token');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('auth_token_type');
      toast.success('Logged out successfully');
      setTimeout(() => {
        window.location.reload();
      }, 1000); 
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(login.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.token = action.payload.access_token;
        state.refreshToken = action.payload.refresh_token;
      })
      .addCase(login.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload.detail;
      })
      .addCase(fetchUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.user = action.payload;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload.detail;
      })
      .addCase(refreshToken.fulfilled, (state, action) => {
        state.token = action.payload.access_token;
      })
      .addCase(googleLogin.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(googleLogin.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.token = action.payload.access_token;
        state.refreshToken = action.payload.refresh_token;
      })
      .addCase(googleLogin.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload.detail;
      });
  },
});

export const { logout, setToken } = authSlice.actions;

export default authSlice.reducer;