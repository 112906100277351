// src/StripeCheckout.js
import React, { useCallback, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { fetchUser } from './redux/authSlice';

const stripePromise = loadStripe("pk_live_51FG4VSGXJ020IFJ8BNFVQHDGP9XZPlOWmQ9lNhymM1gcpNCP5zhGJjZMe7weEGQ6BjuzZ9tpf4FXngHPepMAULcM00Z54jqBfC");

const CheckoutForm = () => {
const user = useSelector((state) => state.auth.user);
 const navigate = useNavigate();
 const dispatch = useDispatch();
 useEffect(() => {
    if (!user ) {
      dispatch(fetchUser());
    }
  }, [user, dispatch]);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const fetchClientSecret = useCallback(() => {
  
    if(!user){
        toast.error("Please login to continue")
        navigate('/login'); 
    }
    return fetch(`${backendUrl}/stripe/create-checkout-session`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('auth_token')}`
      }
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (!data.result.clientSecret) {
          throw new Error("Client secret not found in response");
        }
        return data.result.clientSecret;
      })
      .catch((error) => {
        console.error("Error fetching client secret", error);
        throw error;
      });
  }, []);

  const options = { fetchClientSecret };

  return (
    
    <div id="checkout">
        <div className="flex flex-col space-y-4 px-2 mt-3 ml-3">
          <img src="/logo.png" alt="SumHealth Logo" className="logo mb-8" style={{ width: '150px', height: 'auto' }} />
        </div>
  
      <EmbeddedCheckoutProvider
        stripe={stripePromise}
        options={options}
      >
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  )
}

export default CheckoutForm;



