import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUser, logout } from './redux/authSlice';
import { fetchPrices } from './redux/priceSlice';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import './index.css';
import { styled } from '@mui/material/styles';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TableSortLabel, TextField } from '@mui/material';
import { toast } from 'react-toastify';
import { fetchHospitals } from './redux/hospitalSlice';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import BillingPortalButton from './BillingPortalButton';
import { usePostHog } from 'posthog-js/react'

const StyledAutocomplete = styled(Autocomplete)({
  '& .MuiInputBase-root': {
    backgroundColor: '#F2F5FF',
    borderRadius: '50px',
  },
});

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const posthog = usePostHog()
  const user = useSelector((state) => state.auth.user);
  const token = useSelector((state) => state.auth.token);
  const status = useSelector((state) => state.auth.status);
  const prices = useSelector((state) => state.price.prices);
  const priceStatus = useSelector((state) => state.price.status);

  const hospitals = useSelector((state) => state.hospitals.hospitals);
  const hospitalsStatus = useSelector((state) => state.hospitals.status);
  const storedHospital = localStorage.getItem('selectedHospital') ? JSON.parse(localStorage.getItem('selectedHospital')) : null;

  const [searchTerm, setSearchTerm] = useState(localStorage.getItem('searchTerm') || "");
  const [selectedHospital, setSelectedHospital] = useState(storedHospital);
  const [hospitalInput, setHospitalInput] = useState('');
  const [payer, setPayer] = useState('');
  const [plan, setPlan] = useState('');
  const [payerOptions, setPayerOptions] = useState([]);
  const [planOptions, setPlanOptions] = useState([]);

  const [page, setPage] = useState(parseInt(localStorage.getItem('page')) || 0);
  const [rowsPerPage, setRowsPerPage] = useState(parseInt(localStorage.getItem('rowsPerPage')) || 10);
  const [open, setOpen] = useState(false);

  const defaultFields = ["description", "code", "gross_charge", "maximum", "minimum", "discounted_cash", "payer_name", "setting", "hospital_name",];
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  const [selectedColumns, setSelectedColumns] = useState(
    JSON.parse(localStorage.getItem('selectedColumns')) || defaultFields
  );

  const generalKeys = ["hospital_name", "location", "address", "state", "description", "code", "type", "setting", "drug_type_of_measurement", "drug_unit_of_measurment", "additional_generic_notes", "methodology"]
  const chargeKeys = ["gross_charge", "minimum", "maximum", "discounted_cash", "standard_charge_dollar", "standard_charge_percentage", "standard_charge_algorithm", "estimated_amount"]
  const insuranceKeys = ["plan_name", "payer_name"]
  const otherKeys = ["modifer_code", "modifier_description", "modifier_payer_name", "modifier_plan_name", "modifier_payer_description"]

  useEffect(() => {
    if (!user && token) {
      dispatch(fetchUser());

    }
  }, [user, token, dispatch]);

  useEffect(() => {
    if (user) {
      posthog?.identify(user.email, {
          email: user.email,
          name: user.name
      })
      if (user.subscription_status !== 'active') {
        setOpen(true);
      } else {
        setOpen(false)
      }
    }
  }, [user, posthog]);

  useEffect(() => {
    const storedSearchTerm = localStorage.getItem('searchTerm');
    const storedHospital = localStorage.getItem('selectedHospital') ? JSON.parse(localStorage.getItem('selectedHospital')) : null;
    if (storedSearchTerm) {
      const hospitalId = storedHospital ? storedHospital.id : null;
      dispatch(fetchPrices({ searchTerm: storedSearchTerm.trim(), hospitalId }));
    }
  }, [dispatch]);


  useEffect(() => {
    if (prices.length > 0) {
      // const firstItemKeys = Object.keys(prices[0]).filter(
      //   key => key !== "id" && key !== "search_vector" && key !== "hospital_id"
      // );
      // setOptionalFields(firstItemKeys);

      const uniquePayers = [...new Set(prices.map(item => item.payer_name))].filter(Boolean);
      const uniquePlans = [...new Set(prices.map(item => item.plan_name))].filter(Boolean);

      setPayerOptions(uniquePayers);
      setPlanOptions(uniquePlans);

    }
  }, [prices]);

  // useEffect(() => {
  //   if (searchTerm === '') {
  //     dispatch(fetchHospitals("hopital"));
  //   }
  // }, [searchTerm])



  const handleSearch = () => {

    if (!searchTerm) {
      toast.error("Please include a search query")
      return;
    }
    const hospitalId = selectedHospital ? selectedHospital.id : null;
    dispatch(fetchPrices({
      searchTerm: searchTerm.trim(),
      hospitalId: hospitalId
    }));
    setPlan('');
    setPage(0);
    if (!hospitalId) {
      toast.info("Please enter a hospital name for relevant results!")
    }
    localStorage.setItem('searchTerm', searchTerm ? searchTerm.trim() : '');
    // localStorage.setItem('selectedHospital', selectedHospital);
    localStorage.setItem('selectedHospital', JSON.stringify(selectedHospital));
  };


  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleHospitalChange = (event, newValue) => {
    setSelectedHospital(newValue);
  };

  const debouncedFetchHospitals = useCallback(
    _.debounce((query) => {
      if (query.length >= 2) {
        dispatch(fetchHospitals(query));
      } else {
        setSelectedHospital(null);
      }
    }, 300),
    [dispatch]
  );

  const handleHospitalInputChange = (event, newInputValue) => {
    setHospitalInput(newInputValue);
    debouncedFetchHospitals(newInputValue);
  };

  const handlePayerChange = (event, newValue) => {
    setPayer(newValue);
  };

  const handlePlanChange = (event, newValue) => {
    setPlan(newValue);
  };

  const filteredPrices = prices.filter(item =>
    (payer ? item.payer_name === payer : true) &&
    (plan ? item.plan_name === plan : true)
  );


  const handleLogout = () => {
    localStorage.clear();
    dispatch(logout());
  };

  const handleColumnSelection = (column) => {
    const updatedColumns = selectedColumns.includes(column)
      ? selectedColumns.filter((col) => col !== column)
      : [...selectedColumns, column];

    setSelectedColumns(updatedColumns);
    localStorage.setItem('selectedColumns', JSON.stringify(updatedColumns));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    localStorage.setItem('page', newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const rowsPerPageValue = +event.target.value;
    setRowsPerPage(rowsPerPageValue);
    setPage(0);
    localStorage.setItem('rowsPerPage', rowsPerPageValue);
    localStorage.setItem('page', 0);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedPrices = filteredPrices.slice().sort((a, b) => {
    if (orderBy) {
      const aValue = a[orderBy];
      const bValue = b[orderBy];
      if (order === 'asc') {
        return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
      } else {
        return aValue > bValue ? -1 : aValue < bValue ? 1 : 0;
      }
    }
    return 0;
  });

  const handleSubscribe = () => {
    navigate('/checkout');
  };


  if (status === 'loading') {
    return (<div className="flex justify-center items-center" style={{ height: '75vh' }}>
      <CircularProgress />
    </div>)
  }

  const capitalizeWords = (str) => {
    return str.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
  };


  const getSidebarButtons = () => {

    if (prices.length === 0) return null;

    const buttonTextStyle = {
      fontFamily: 'Arial',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: '12px',
      lineHeight: '20px',
      letterSpacing: '0.1px',
      color: '#FFFFFF',
      padding: '0 10px',
    };

    return (
      <>
        <span className="text-[12px] text-[#2D3748] font-bold" style={{ lineHeight: "18px" }}>General</span>
        <div className='w-full flex flex-col gap-[4px]' style={{ marginTop: "1rem", marginBottom: "1rem" }}>
          {generalKeys.map(key => (
            <div key={key} >
              <button key={key} className={`btn-general text-left flex items-center px-3 ${selectedColumns.includes(key) ? 'selected' : ''}`} onClick={() => handleColumnSelection(key)}>
                {selectedColumns.includes(key) ? <MinusIcon /> : <PlusIcon />}
                <span style={buttonTextStyle}>{capitalizeWords(key)}</span>
              </button>
            </div>
          ))}
        </div>
        <span className="text-[12px] text-[#2D3748] font-bold" style={{ lineHeight: "18px" }}>Insurance</span>
        <div className='w-full flex flex-col gap-[4px]' style={{ marginTop: "1rem", marginBottom: "1rem" }}>
          {insuranceKeys.map(key => (
            <div key={key}>
              <button key={key} className={`btn-insurance text-left flex items-center px-3 ${selectedColumns.includes(key) ? 'selected' : ''}`} onClick={() => handleColumnSelection(key)}>
                {selectedColumns.includes(key) ? <MinusIcon /> : <PlusIcon />}
                <span style={buttonTextStyle}>{capitalizeWords(key)}</span>
              </button>
            </div>
          ))}
        </div>
        <span className="text-[12px] text-[#2D3748] font-bold" style={{ lineHeight: "18px" }}>Charges</span>
        <div className='w-full flex flex-col gap-[4px]' style={{ marginTop: "1rem", marginBottom: "1rem" }}>
          {chargeKeys.map(key => (
            <div key={key}>
              <button key={key} className={`btn-charges text-left flex items-center px-3 py-1 ${selectedColumns.includes(key) ? 'selected' : ''}`} onClick={() => handleColumnSelection(key)}>
                {selectedColumns.includes(key) ? <MinusIcon /> : <PlusIcon />}
                <span style={buttonTextStyle}>{capitalizeWords(key)}</span>
              </button>
            </div>
          ))}
        </div>

        <span className="text-[12px] text-[#2D3748] font-bold" style={{ lineHeight: "18px" }}>Other</span>
        <div className='w-full flex flex-col gap-[4px]' style={{ marginTop: "1rem", marginBottom: "1rem" }}>
          {otherKeys.map(key => (
            <div key={key}>
              <button key={key} className={`btn-charges text-left flex items-center px-3 py-1 ${selectedColumns.includes(key) ? 'selected' : ''}`} onClick={() => handleColumnSelection(key)}>
                {selectedColumns.includes(key) ? <MinusIcon /> : <PlusIcon />}
                <span style={buttonTextStyle}>{capitalizeWords(key)}</span>
              </button>
            </div>
          ))}
        </div>
      </>
    );
  };


  const columns = selectedColumns.map((key) => ({
    id: key,
    label: key.replace(/_/g, ' ').toUpperCase(),
    minWidth: 100,
  }));

  return (
    <div className="main-container">
      {/* Sidebar */}
      <div className="left-sidepanel " >
        <img src="/logo.png" alt="SumHealth Logo" className="logo mb-8" />
        <div className="flex flex-col custom-scrollbar">

          <span className="text-[16px] text-[#2D3748] font-bold mb-8">Filters</span>
          <div className="space-y-2 ">
            {getSidebarButtons()}
          </div>
        </div>
      </div>


      {/* Main Content */}
      <div className="main-content">
        <header className="payments-section-header mb-9 flex justify-between items-center">
          <span className='inter-font' style={{ color: '#111560', fontWeight: 700, fontSize: '28px', lineHeight: '34px', letterSpacing: '-0.01em' }}>
            Price Transparency
          </span>


          <div className="flex items-center space-x-2">
            <div className="flex items-center space-x-1 mr-4">
              {user && (
                <>
                  <div className="flex items-center space-x-1 mr-4">
                    <UserSvg />
                    <span className='text-[12px]' style={{ color: "gray", marginLeft: "5px" }}>{capitalizeWords(user.username)}</span>
                  </div>
                  {user.subscription_status === 'active' && (
                    <BillingPortalButton />
                  )}
                  <button onClick={handleLogout} style={{ color: 'gray', fontSize: '0.8rem', paddingLeft: '1.25rem' }}>Logout</button>
                </>
              )}
            </div>
          </div>
        </header>

        <Dialog open={open} onClose={() => { }} disableEscapeKeyDown>
          {user && user.subscription_status !== 'active' && user.had_trial ? (
            <>
              <DialogTitle>Hi <DialogTitle> {user.name} </DialogTitle></DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Your 7-day free trial has ended. Activate your subscription to unlock full features.
                </DialogContentText>
              </DialogContent>
            </>
          ) : (
            <>
              <DialogTitle>Welcome to SumHealth!</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Click “Subscribe” below to begin your 7-day free trial. Just $99/month after that. No commitments. Cancel anytime.
                </DialogContentText>
              </DialogContent>
            </>
          )}
          <DialogActions>
            <Button color='secondary' onClick={handleLogout} >
              Logout
            </Button>

            <Button onClick={handleSubscribe} color="primary">Subscribe</Button>
          </DialogActions>
        </Dialog>
        {/* <div className='mb-6 flex flex-row items-center gap-[20px]' > */}
        <div className="search-bar mb-6 flex flex-row items-center flex-wrap">
          <FormControl variant="outlined">
            <InputLabel htmlFor="search-input" sx={{
              marginLeft: '12px',
              top: '50%',
              transform: 'translateY(-50%)',
              '&.MuiInputLabel-shrink': {
                top: -5,
                transform: 'translateY(-50%) scale(0.75)',
                color: '#A0AAB4',
              },
            }}> Search procedure or code</InputLabel>
            <OutlinedInput
              id="search-input"
              value={searchTerm}
              onKeyDown={handleKeyDown}
              onChange={(e) => setSearchTerm(e.target.value)}
              label="Search procedure or CPT code"
              style={{
                backgroundColor: '#F2F5FF',
                borderRadius: '50px',
                width: '340px',
                height: '40px',
              }}
              // className="md:w-1/2 lg:w-1/2"
              sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'transparent',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'transparent',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'transparent',
                  color: '#A0AAB4'
                },
              }}
            />
          </FormControl>
          <StyledAutocomplete
            value={selectedHospital}
            onChange={handleHospitalChange}
            inputValue={hospitalInput}
            onInputChange={handleHospitalInputChange}
            options={hospitals}
            getOptionLabel={(option) => (option && option.name) || ""}
            noOptionsText="Begin typing for hospital suggestions"
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search Hospital"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {hospitalsStatus === 'loading' ? <CircularProgress size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
                sx={{
                  '& .MuiInputLabel-outlined': {
                    marginLeft: '12px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    '&.MuiInputLabel-shrink': {
                      top: -5,
                      transform: 'translateY(-50%) scale(0.75)',
                      color: '#A0AAB4',
                    },
                  },
                  '& .MuiOutlinedInput-root': {
                    height: '40px',
                    width: '340px',
                  },
                }}
              />
            )}
            sx={{
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'transparent',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: 'transparent',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'transparent',
                // color: '#A0AAB4',
              },
            }}
          />
          {prices.length > 0 && (
            <>
              <StyledAutocomplete
                value={payer}
                onChange={handlePayerChange}
                options={payerOptions}
                getOptionLabel={(option) => option || ""}
                noOptionsText="No payers found"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Filter by Payer"
                    variant="outlined"
                    sx={{
                      '& .MuiInputLabel-outlined': {
                        marginLeft: '12px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        '&.MuiInputLabel-shrink': {
                          top: -5,
                          transform: 'translateY(-50%) scale(0.75)',
                          color: '#A0AAB4',
                        },
                      },
                      '& .MuiOutlinedInput-root': {
                        height: '40px',
                        width: "340px"
                      },

                    }}
                  />
                )}
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                  },
                }}
              />

              <StyledAutocomplete
                value={plan}
                onChange={handlePlanChange}
                options={planOptions}
                getOptionLabel={(option) => option || ""}
                noOptionsText="No plans found"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Filter by Plan"
                    variant="outlined"
                    sx={{
                      '& .MuiInputLabel-outlined': {
                        marginLeft: '12px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        '&.MuiInputLabel-shrink': {
                          top: 0,
                          transform: 'translateY(-50%) scale(0.75)',
                          color: '#A0AAB4',
                        },
                      },
                      '& .MuiOutlinedInput-root': {
                        height: '40px',
                        width: "340px"
                      },
                    }}
                  />
                )}
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                  },
                }}
              />
            </>
          )}
          <button
            onClick={handleSearch}
            className="search-button bg-search text-white rounded-full font-bold hover:bg-custom-blue w-[133px] h-[40px] text-[14px]"
          >
            Search
          </button>
        </div>
        {/* </div> */}



        <div className="results">
          {priceStatus === 'loading' ? (
            <div className="flex justify-center items-center" style={{ height: '75vh' }}>
              <CircularProgress />
            </div>
          ) : (
            <>
              {filteredPrices.length > 0 ? (
                <Paper sx={{ width: '100%', overflow: 'hidden', boxShadow: 'none' }}>
                  <TableContainer sx={{ maxHeight: '72vh', border: 'none' }} className="custom-scrollbar">
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow sx={{ borderBottom: 'none' }}>
                          {columns.map((column) => (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{
                                minWidth: column.minWidth,
                                padding: '6px 16px',
                                fontFamily: 'Arial',
                                fontStyle: 'normal',
                                fontWeight: 700,
                                fontSize: '10px',
                                lineHeight: '150%',
                                color: '#A0AEC0',
                                borderBottom: 'none',
                                textTransform: 'uppercase',
                              }}
                              sortDirection={orderBy === column.id ? order : false}
                            >
                              <TableSortLabel
                                active={orderBy === column.id}
                                direction={orderBy === column.id ? order : 'asc'}
                                onClick={(event) => handleRequestSort(event, column.id)}
                              >
                                {column.label}
                              </TableSortLabel>

                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {sortedPrices.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                          <TableRow hover style={{ "height": '56px' }} role="checkbox" tabIndex={-1} key={index}>
                            {columns.map((column) => {
                              const value = row[column.id];
                              const isNumber = !isNaN(value) && value !== null && value !== '';
                              const isChargeKey = ['code', 'search_vector', 'id', 'hospital_id', 'standard_charge_percentage'].includes(column.id)
                              return (
                                <TableCell key={column.id}
                                  size='small'
                                  align={column.align}
                                  style={{
                                    width: '240px',
                                    height: '20px',
                                    fontFamily: 'Arial',
                                    fontStyle: 'normal',
                                    fontWeight: 700,
                                    fontSize: '14px',
                                    lineHeight: '1.5em',
                                    height: '3em',
                                    color: '#2D3748',
                                    flex: 'none',
                                    order: 1,
                                    flexGrow: 0,
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                  }}
                                >
                                  {isNumber && !isChargeKey ? (
                                    <span className="number-value">${parseFloat(value).toFixed(2).toLocaleString()}</span>
                                  ) : (
                                    value ? value : <span className="not-provided">Not Provided</span>
                                  )}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={prices.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Paper>
              ) : (
                <div className="flex justify-center items-center" style={{ height: '75vh' }}>
                  <p>No results found.</p>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const PlusIcon = () => (
  <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-2">
    <path d="M15 10.3118H9.75V15.5618H8.25V10.3118H3V8.81177H8.25V3.56177H9.75V8.81177H15V10.3118Z" fill="white" />
  </svg>
);

const MinusIcon = () => (
  <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-2">
    <path d="M15 10.3118H3V8.81177H15V10.3118Z" fill="white" />
  </svg>
);

const UserSvg = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.79609 1.51335C7.33999 1.02093 6.70296 0.749756 5.99984 0.749756C5.29296 0.749756 4.65382 1.01929 4.19984 1.50866C3.74093 2.00343 3.51734 2.67585 3.56984 3.40194C3.6739 4.83444 4.76398 5.99975 5.99984 5.99975C7.2357 5.99975 8.3239 4.83468 8.4296 3.40241C8.48281 2.68288 8.25781 2.01186 7.79609 1.51335Z" fill="#718096" />
    <path d="M10.1248 11.2493H1.87483C1.76684 11.2507 1.6599 11.228 1.56179 11.1829C1.46367 11.1377 1.37685 11.0713 1.30764 10.9884C1.1553 10.8063 1.09389 10.5576 1.13936 10.3061C1.33717 9.2088 1.95452 8.287 2.92483 7.63989C3.78686 7.06544 4.87881 6.74927 5.99983 6.74927C7.12084 6.74927 8.21279 7.06567 9.07482 7.63989C10.0451 8.28677 10.6625 9.20856 10.8603 10.3059C10.9058 10.5574 10.8444 10.8061 10.692 10.9882C10.6228 11.0711 10.536 11.1376 10.4379 11.1828C10.3398 11.2279 10.2328 11.2506 10.1248 11.2493Z" fill="#718096" />
  </svg>
)

const SearchSvg = () => (
  <svg width="28" height="20" viewBox="0 0 28 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.6302 14.8466L16.8736 12.0901C17.5373 11.2066 17.8955 10.1312 17.8943 9.02622C17.8943 6.20376 15.598 3.90747 12.7756 3.90747C9.95311 3.90747 7.65682 6.20376 7.65682 9.02622C7.65682 11.8487 9.95311 14.145 12.7756 14.145C13.8806 14.1462 14.9559 13.7879 15.8394 13.1243L18.596 15.8808C18.7355 16.0055 18.9175 16.0721 19.1046 16.0669C19.2917 16.0616 19.4697 15.985 19.602 15.8526C19.7343 15.7203 19.811 15.5423 19.8162 15.3552C19.8215 15.1682 19.7549 14.9862 19.6302 14.8466ZM9.11932 9.02622C9.11932 8.30308 9.33375 7.59618 9.73551 6.99492C10.1373 6.39365 10.7083 5.92502 11.3764 5.64829C12.0445 5.37155 12.7796 5.29915 13.4889 5.44022C14.1981 5.5813 14.8496 5.92952 15.3609 6.44086C15.8723 6.9522 16.2205 7.60368 16.3616 8.31292C16.5026 9.02216 16.4302 9.75731 16.1535 10.4254C15.8768 11.0935 15.4081 11.6645 14.8069 12.0663C14.2056 12.468 13.4987 12.6825 12.7756 12.6825C11.8062 12.6813 10.8769 12.2957 10.1915 11.6103C9.50607 10.9249 9.12048 9.99556 9.11932 9.02622Z" fill="#2D3748" />
  </svg>

)
export default Home;