import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link as RouterLink, useLocation } from "react-router-dom";
import { googleLogin, login, register } from "../redux/authSlice";
import { TextField, Checkbox, FormControlLabel, Button, CircularProgress, Link, Box, Divider } from '@mui/material';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { toast } from "react-toastify";

export default function AuthForm({ mode }) {
  const [authForm, setAuthForm] = useState({
    name: "",
    username: "",
    email: "",
    identifier: "",  // Added this field
    password: "",
    phone_number: "",  // Optional
    birth: "",  // Optional
  sex: "NONE",  // Default value to NONE
    profile: "base64"  // Default value
  });

  const [staySignedIn, setStaySignedIn] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const authStatus = useSelector((state) => state.auth.status);
  const from = location.state?.from?.pathname || "/";

  const onChangeForm = (label, event) => {
    setAuthForm({ ...authForm, [label]: event.target.value });
  };

  const onSubmitHandler = async (event) => {
    event.preventDefault();
    if (mode === "login") {
      if (!authForm.identifier) {
        toast.error("Please enter your email or username");
        return;
      }
      if (!authForm.password) {
        toast.error("Please enter your password");
        return;
      }
      const loginForm = {
        identifier: authForm.identifier,
        password: authForm.password
      };
      const resultAction = await dispatch(login(loginForm));
      if (login.fulfilled.match(resultAction)) {
        navigate(from, { replace: true });
      }
    } else if (mode === "register") {
      if (!authForm.name) {
        toast.error("Please enter your name");
        return;
      }
      if (!authForm.email) {
        toast.error("Please enter your email");
        return;
      }
      if (!authForm.password) {
        toast.error("Please enter your password");
        return;
      }
      const registerForm = {
        name: authForm.name,
        username: authForm.username,
        email: authForm.email,
        password: authForm.password,
        phone_number: authForm.phone_number,
        birth: authForm.birth,
        sex: authForm.sex,
        profile: authForm.profile
      };
      const resultAction = await dispatch(register(registerForm));
      if (register.fulfilled.match(resultAction)) {
        navigate("/login");
      }
    }
  };

  const handleGoogleLoginSuccess = async (response) => {
    const resultAction = await dispatch(googleLogin(response.credential));
    if (googleLogin.fulfilled.match(resultAction)) {
      navigate(from, { replace: true });
    }
  };

  const handleGoogleLoginFailure = (error) => {
    console.error("Google login failed", error);
  };

  return (
    <GoogleOAuthProvider clientId="635659641272-ti5j85720jbd89osdrs7lb7k7mnp9n2b.apps.googleusercontent.com">
      <div className="flex items-center justify-center min-h-screen font-sans mt-4">
        <div className="w-full max-w-md p-8 space-y-8 bg-white shadow-xl rounded-xl border border-gray-200 relative">
        <div className="text-center">
            <img src="/logo.png" alt="SumHealth Logo" className="mx-auto h-12 w-auto" />
          </div>
          <div className="text-center mt-16">
            <h2 className="mt-6 text-gray-900" style={{ 
              fontWeight: 700,
              fontSize: '24px',
              lineHeight: '29px',
              display: 'flex',
              alignItems: 'center',
              color: '#101939',
            }}>{mode === "login" ? "Sign in to your account" : "Register your account"}</h2>
          </div>
          <form className="mt-8 space-y-6" onSubmit={onSubmitHandler}>
            <div className="rounded-md shadow-sm space-y-4">
              {mode === "register" && (
                <>
                  <TextField
                    id="name"
                    name="name"
                    label="Name"
                    variant="outlined"
                    fullWidth
                    autoComplete="name"
                    onChange={(event) => onChangeForm("name", event)}
                    style={{
                      marginBottom: '10px'
                    }}
                  />
                  <TextField
                    id="username"
                    name="username"
                    label="Username"
                    variant="outlined"
                    fullWidth
                    autoComplete="username"
                    onChange={(event) => onChangeForm("username", event)}
                    style={{
                      marginBottom: '10px'
                    }}
                  />
                  
                </>
              )}
              {mode == 'login' && (
                 <TextField
                 id="identifier"
                 name="identifier"
                 label="Email or Username"
                 variant="outlined"
                 fullWidth
                 autoComplete="identifier"
                 onChange={(event) => onChangeForm("identifier", event)}
                 style={{
                   marginBottom: '10px'
                 }}
               />
              )}

              {mode === 'register' && (
                 <TextField
                 id="email"
                 name="email"
                 label="Email"
                 variant="outlined"
                 fullWidth
                 autoComplete="email"
                 onChange={(event) => onChangeForm("email", event)}
                 style={{
                   marginBottom: '10px'
                 }}
               />
              )}
             
              <TextField
                id="password"
                name="password"
                label="Password"
                type="password"
                variant="outlined"
                fullWidth
                autoComplete="current-password"
                onChange={(event) => onChangeForm("password", event)}
              />
            </div>
            <div className="flex items-center justify-between">
              <FormControlLabel
                control={
                  <Checkbox
                    id="stay-signed-in"
                    name="staySignedIn"
                    color="primary"
                    checked={staySignedIn}
                    onChange={(e) => setStaySignedIn(e.target.checked)}
                  />
                }
                label="Stay signed in for a week"
                sx={{
                  fontFamily: 'SF Pro Text',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '14px',
                  lineHeight: '17px',
                  color: '#3C4257',
                }}
              />
              <div className="text-sm">
                <Link href="#" variant="body2" sx={{ textDecoration: 'none', color: '#5167F6' }}>
                  Forgot your password?
                </Link>
              </div>
            </div>
            <div>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={authStatus === 'loading'}
                style={{
                  padding: '13.5px 10px',
                  background: '#5167F6',
                  borderRadius: '30px',
                }}
              >
                {authStatus === 'loading' ? 'Processing...' : mode === "login" ? 'Continue' : 'Register'}
              </Button>
            </div>
            <Box display="flex" alignItems="center" mt={2} mb={2}>
              <Divider style={{ flexGrow: 1 }} />
              <span style={{ margin: '0 10px', color: '#888' }}>OR</span>
              <Divider style={{ flexGrow: 1 }} />
            </Box>
            <div className="flex justify-center">
              <GoogleLogin
                onSuccess={handleGoogleLoginSuccess}
                onFailure={handleGoogleLoginFailure}
              />
            </div>
          </form>
          <div className="text-center text-sm">
            {mode === "login" ? (
              <>
                Don’t have an account?{" "}
                <Link component={RouterLink} to="/register" variant="body2" sx={{ textDecoration: 'none', color: '#5167F6' }}>
                  Sign up
                </Link>
              </>
            ) : (
              <>
                Already have an account?{" "}
                <Link component={RouterLink} to="/login" variant="body2" sx={{ textDecoration: 'none', color: '#5167F6' }}>
                  Sign in
                </Link>
              </>
            )}
          </div>
          <div className="text-center text-xs text-gray-500">
            © SumHealth 2024 • <Link href="https://www.sumhealth.org/contact"  variant="body2" sx={{ textDecoration: 'none', color: '#5167F6' }}>Contact</Link> • <Link href="https://www.sumhealth.org/privacy-policy" variant="body2" sx={{ textDecoration: 'none', color: '#5167F6' }}>Privacy & terms</Link>
          </div>
        </div>
      </div>
    </GoogleOAuthProvider>
  );
}
