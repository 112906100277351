// src/redux/hospitalSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import posthog from 'posthog-js';
const initialState = {
  hospitals: [],
  status: 'idle',
  error: null,
};
const backendUrl = process.env.REACT_APP_BACKEND_URL;

export const fetchHospitals = createAsyncThunk(
  'hospitals/fetchHospitals',
  async (query, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      posthog.capture('hospital', {'name': query});
      
      const response = await axios.get(`${backendUrl}/search/hospital-names`, {
        params: { query },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data.result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
const hospitalSlice = createSlice({
  name: 'hospitals',
  initialState,
  reducers: {
    resetHospitals: (state) => {
        state.hospitals = [];
        state.status = 'idle';
        state.error = null;
      },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHospitals.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchHospitals.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.hospitals = action.payload;
      })
      .addCase(fetchHospitals.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});
export const { resetHospitals } = hospitalSlice.actions;
export default hospitalSlice.reducer;
