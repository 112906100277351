import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { toast } from 'react-toastify';

const BillingPortalButton = () => {
  const [loading, setLoading] = useState(false);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const handleBillingPortal = () => {
    setLoading(true);
    fetch(`${backendUrl}/stripe/create-billing-portal-session`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('auth_token')}`,
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then(data => {
        if (data.result && data.result.url) {
          window.location.href = data.result.url;
        } else {
          toast.error("Failed to create billing portal session");
        }
      })
      .catch(error => {
        console.error("Error creating billing portal session", error);
        toast.error("An error occurred while creating billing portal session");
      })
      .finally(() => setLoading(false));
  };

  return (
    <button onClick={handleBillingPortal}   style={{ color: 'gray', fontSize: '0.8rem' }} disabled={loading}> {loading ? 'Loading...' : 'Manage subscription'}</button>
  );
};

export default BillingPortalButton;
