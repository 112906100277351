import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { Box, CircularProgress, Paper, TextField, Typography, List, ListItem } from '@mui/material';
import './index.css';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const Hospitals = () => {
  const token = useSelector((state) => state.auth.token);
  const [hospitals, setHospitals] = useState([]);
  const [filteredHospitals, setFilteredHospitals] = useState([]);
  const [uniqueStates, setUniqueStates] = useState([]);
  const [selectedState, setSelectedState] = useState('ALL');
  const [hospitalInput, setHospitalInput] = useState('');
  const [loading, setLoading] = useState(false);

  const fetchHospitals = async () => {
    try {
      setLoading(true);
      console.log("here")
      console.log(backendUrl)
      const response = await axios.get(`${backendUrl}/search/hospitals`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const hospitalsData = response.data.result;
      setHospitals(hospitalsData);
      setFilteredHospitals(hospitalsData);

      // Extract unique states and add "ALL" option
      const states = ['ALL', ...new Set(hospitalsData.map(hospital => hospital.state))];
      setUniqueStates(states);

      setLoading(false);
    } catch (error) {
      console.error('Error fetching hospitals:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    // if (token) {
      fetchHospitals();
    // }
  }, [token]);

  const handleHospitalInputChange = (event) => {
    const value = event.target.value;
    setHospitalInput(value);
    filterHospitals(selectedState, value);
  };

  const filterHospitals = (state, searchTerm) => {
    let filtered = hospitals;
    if (state && state !== 'ALL') {
      filtered = filtered.filter(hospital => hospital.state === state);
    }
    if (searchTerm) {
      filtered = filtered.filter(hospital =>
        hospital.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        hospital.location.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    setFilteredHospitals(filtered);
  };

  const handleStateClick = (state) => {
    setSelectedState(state);
    filterHospitals(state, hospitalInput);
  };

  return (
    <div className="main-container">
      {/* Sidebar */}
      <div className="left-sidepanel custom-scrollbar">
        <div className="flex flex-col space-y-4 px-2">
          <img src="/logo.png" alt="SumHealth Logo" className="logo mb-8" />
          <Typography variant="h6" component="div" style={{ marginBottom: '10px' }}>
            Filter by state
          </Typography>
          <List>
            {uniqueStates.map((state, index) => (
              <ListItem 
                key={index} 
                button 
                onClick={() => handleStateClick(state)} 
                selected={selectedState === state}
              >
                <Typography variant="body1">
                  {state}
                </Typography>
              </ListItem>
            ))}
          </List>
        </div>
      </div>

      {/* Main Content */}
      <div className="main-content">
        <header className="payments-section-header mb-9 flex justify-between items-center">
          <Typography
            variant="h1"
            component="h1"
            style={{ color: '#111560', fontFamily: 'serif', fontWeight: 700, fontSize: '32px', lineHeight: '42px', letterSpacing: '-0.01em' }}
          >
            Hospitals
          </Typography>
          <Typography
            variant="body2"
            component="p"
            style={{ color: '#6B7280', fontFamily: 'Arial', fontSize: '14px', marginTop: '8px' }}
          >
            We are adding new hospital data each month to ensure you have the most up-to-date information.
          </Typography>
        </header>

        <div className="search-bar mb-6 flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-4">
          <TextField
            value={hospitalInput}
            onChange={handleHospitalInputChange}
            label="Search Hospital"
            variant="outlined"
            fullWidth
            sx={{
              '& .MuiInputLabel-outlined': {
                marginLeft: '12px',
                top: '50%',
                transform: 'translateY(-50%)',
                '&.MuiInputLabel-shrink': {
                  top: 0,
                  transform: 'translateY(-50%) scale(0.75)',
                  color: '#A0AAB4',
                },
              },
              '& .MuiOutlinedInput-root': {
                height: '2.6em',
                backgroundColor: '#F2F5FF',
                borderRadius: '50px',
              },
            }}
          />
        </div>

        {loading ? (
          <div className="flex justify-center items-center" style={{ height: '75vh' }}>
            <CircularProgress />
          </div>
        ) : (
          <Paper sx={{ width: '100%', overflow: 'hidden', boxShadow: 'none', padding: '16px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '12px 24px', borderBottom: '2px solid #ddd', fontWeight: 700 }}>
              <Typography variant="h7" component="div">
                NAME
              </Typography>
              <Typography variant="h7" component="div">
                LOCATION
              </Typography>
            </div>
            <List sx={{ maxHeight: '72vh', overflow: 'auto', border: 'none' }} className="custom-scrollbar">
              {filteredHospitals.map((hospital, index) => (
                <ListItem key={index} style={{ padding: '12px 24px', borderBottom: '1px solid #ddd', display: 'flex', justifyContent: 'space-between' }}>
                  <span><strong>{hospital.name}</strong></span>
                  <span>{hospital.location}</span>
                </ListItem>
              ))}
            </List>
          </Paper>
        )}
      </div>
    </div>
  );
};

export default Hospitals;
