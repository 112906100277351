// src/Return.js
import React, { useState, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { fetchUser } from './redux/authSlice';
import { useDispatch } from 'react-redux';

const Return = () => {
  const [status, setStatus] = useState(null);
  const [customerEmail, setCustomerEmail] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get('session_id');

    fetch(`${backendUrl}/stripe/session-status?session_id=${sessionId}`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('auth_token')}`
      }
    })
      .then((res) => res.json())
      .then((data) => {
        setStatus(data.result.status);
        setCustomerEmail(data.result.customer_email);
      });
  }, []);

  useEffect(() => {
    if (status === 'complete') {
      toast.success(`You are now subsribed to pro! A confirmation email will be sent to ${customerEmail}. Redirecting...`, {
        autoClose: 5000, 
      });

      const timeout = setTimeout(() => {
        dispatch(fetchUser()); 
        navigate('/home'); 
      }, 5000);

      return () => clearTimeout(timeout);
    }
  }, [status, customerEmail, navigate]);

  if (status === 'open') {
    return (
      <Navigate to="/checkout" />
    )
  }

  return null;
}

export default Return;
