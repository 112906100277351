// src/redux/store.js
import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import priceReducer from './priceSlice';
import hospitalSystemsReducer from './hospitalSystemsSlice';
import hospitalReducer from './hospitalSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    price: priceReducer,
    hospitalSystems: hospitalSystemsReducer,
    hospitals: hospitalReducer,
  },
});


