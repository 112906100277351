import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import { PostHogProvider} from 'posthog-js/react'

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  person_profiles: 'identified_only',
}
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <PostHogProvider 
    apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
      options={options}
    >
    <Provider store={store}>
      <App />
    </Provider>
    </PostHogProvider>
  </React.StrictMode>
);

reportWebVitals();
